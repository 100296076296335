import { firebaseConfig } from "../../firebaseConfig";

const googleSearchAPIKey = firebaseConfig.googleSearchEngineAPIKey;
const googleSearchEngineID = firebaseConfig.googleSearchEngineId;

// convert the above export into a function that I can use in my app to get the top k results from a google search
export const googleSearch = async (query: string, numResults: number = 5) => {
    try {
        const response = await fetch(`https://www.googleapis.com/customsearch/v1?key=${googleSearchAPIKey}&cx=${googleSearchEngineID}&q=${query}&num=${numResults}`);
        const data = await response.json();
        console.log(data.items);
        const dataItems = data.items;
        let searchResults = "\n\n";
        dataItems.forEach((item: any, index: number) => {
            searchResults += `${index + 1}. [${item.title}](${item.link})
        - ${item.snippet}

    `;
        });
        return searchResults;
    } catch (error) {
        console.error(error);
        return "Error: Unable to retrieve search results.";
    }
}

