import React, { useState, useRef, useEffect } from "react";
import { MdAdd, MdMoreVert, MdClose } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import {
    deleteScriptFromFirestore,
    saveScriptToFirestore,
    renameScriptInFirestore,
} from "../control/firebase";
import { downloadHTMLScript, downloadOpenscadScript } from "../control/agentTools";

const darkModeColors = {
    background: '#2C2F33',
    foreground: '#23272A',
    primary: '#7289DA',
    text: '#FFFFFF',
    border: '#3A3C41'
};

function ScriptsOverlay({ scripts, setScripts, selectedScript, setSelectedScript, closeOverlay }) {
    const [activeCard, setActiveCard] = useState(null);
    const [renameScriptId, setRenameScriptId] = useState(null);
    const [editScript, setEditScript] = useState(null);
    const [temporaryEditContent, setTemporaryEditContent] = useState("");
    const [showAddForm, setShowAddForm] = useState({ webApps: false, openSCAD: false });
    const [versionOverlay, setVersionOverlay] = useState(null);
    const [currentVersion, setCurrentVersion] = useState({});
    const activeCardRef = useRef(null);
    const versionOverlayRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeCardRef.current && !activeCardRef.current.contains(event.target)) {
                setActiveCard(null);
            }
            if (versionOverlayRef.current && !versionOverlayRef.current.contains(event.target)) {
                setVersionOverlay(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [activeCardRef, versionOverlayRef]);

    const handleAddScriptClick = (category) => {
        setShowAddForm((prev) => ({ ...prev, [category]: true }));
    };

    const handleCancelAddScript = (category) => {
        setShowAddForm((prev) => ({ ...prev, [category]: false }));
    };

    const handleSaveScript = (category) => {
        const nameInput = document.getElementById(`${category}-name-input`).value;
        const contentInput = document.getElementById(`${category}-content-input`).value;
        if (nameInput && contentInput) {
            const newScript = { id: Date.now(), name: nameInput, content: contentInput, scriptType: category };
            setScripts((prevState) => ({
                ...prevState,
                [category]: [...prevState[category], newScript],
            }));
            const userID = localStorage.getItem("userID");
            saveScriptToFirestore(userID, contentInput, category, nameInput);
            setShowAddForm((prev) => ({ ...prev, [category]: false }));
        }
    };

    const handleSelectScript = (script) => {
        localStorage.setItem(
            "workingOnScript",
            JSON.stringify({ script: script.name, contents: script.content, scriptType: script.scriptType })
        );
        setSelectedScript(script.name);
        setCurrentVersion((prevState) => ({ ...prevState, [script.scriptType]: script }));
    };

    const handleDeselectScript = () => {
        localStorage.removeItem("workingOnScript");
        setSelectedScript(null);
    };

    const handleRenameScript = (category, id, newName) => {
        setScripts((prevState) => ({
            ...prevState,
            [category]: prevState[category].map((script) =>
                script.id === id ? { ...script, name: newName } : script
            ),
        }));
        const userID = localStorage.getItem("userID");
        const script = scripts[category].find((script) => script.id === id);
        renameScriptInFirestore(userID, category, script.name, newName);
        setRenameScriptId(null);
    };

    const handleDeleteScript = async (category, currentScript) => {
        setScripts((prevState) => ({
            ...prevState,
            [category]: prevState[category].filter((script) => script.id !== currentScript.id),
        }));
        setActiveCard(null);
        const userID = localStorage.getItem("userID");
        await deleteScriptFromFirestore(userID, category, currentScript.name);
        if (selectedScript === currentScript.name) {
            localStorage.removeItem("workingOnScript");
            setSelectedScript(null);
            setCurrentVersion((prevState) => ({ ...prevState, [category]: undefined }));
        }
    };

    const handlePlayScript = (script) => {
        const { content, name, scriptType } = script;
        if (scriptType === "webApps") {
            downloadHTMLScript(content, name);
        } else if (scriptType === "openSCAD") {
            downloadOpenscadScript(content, name);
        }
    };

    const handleEditScript = (script) => {
        setTemporaryEditContent(script.content);
        setEditScript(script.id);
    };

    const handleSaveEdit = (category, id) => {
        setScripts((prevState) => ({
            ...prevState,
            [category]: prevState[category].map((s) =>
                s.id === id ? { ...s, content: temporaryEditContent } : s
            ),
        }));
        setEditScript(null);
        const userID = localStorage.getItem("userID");
        // last param is skipBackup and we set to true to not duplicate since this was a human made edit from the UI
        saveScriptToFirestore(userID, temporaryEditContent, category, scripts[category].find((s) => s.id === id).name, true);
    };

    const handleCancelEditScript = () => {
        setEditScript(null);
    };

    const handleVersionButtonClick = (baseName) => {
        setVersionOverlay(baseName);
    };

    const handleSelectVersion = (versionedScript) => {
        handleSelectScript(versionedScript);
        setVersionOverlay(null);
    };

    const getBaseName = (name) => {
        const match = name.match(/^[^\-]+/);
        return match ? match[0].replace(/([a-z])([A-Z])/g, '$1 $2').trim() : name;
    };

    const getScriptsByBaseName = (scripts) => {
        const grouped = {};
        scripts.forEach((script) => {
            const baseName = getBaseName(script.name.replace(/ /g, ""));
            if (!grouped[baseName]) grouped[baseName] = [];
            grouped[baseName].push(script);
        });
        return grouped;
    };

    const renderScripts = (category) => {
        const groupedScripts = getScriptsByBaseName(scripts[category]);
        return (
            <>
                {Object.keys(groupedScripts).map((baseName) => {
                    const scriptsList = groupedScripts[baseName];
                    const currentScript = currentVersion[category] && scriptsList.find(s => s.name === currentVersion[category].name) || scriptsList[0];
                    const hasMultipleVersions = scriptsList.length > 1;

                    return (
                        <div
                            key={currentScript.id}
                            style={{
                                ...styles.scriptCard,
                                borderColor: selectedScript === currentScript.name ? darkModeColors.primary : darkModeColors.border,
                            }}
                        >
                            <div style={styles.scriptCardHeader}>
                                {renameScriptId === currentScript.id ? (
                                    <input
                                        type="text"
                                        defaultValue={currentScript.name}
                                        onBlur={(e) => handleRenameScript(category, currentScript.id, e.target.value)}
                                        style={styles.renameInput}
                                        autoFocus
                                    />
                                ) : (
                                    <p style={styles.scriptName}>{currentScript.name}</p>
                                )}
                                <div style={styles.actions}>
                                    <FaPlay className="play-icon" style={styles.playIcon} onClick={() => handlePlayScript(currentScript)} />
                                    <button style={styles.selectButton} onClick={() => handleSelectScript(currentScript)}>
                                        Select
                                    </button>
                                    <MdMoreVert
                                        className="more-icon"
                                        style={styles.moreIcon}
                                        onClick={() => {
                                            setActiveCard(currentScript.id);
                                        }}
                                    />
                                    {activeCard === currentScript.id && (
                                        <div
                                            className="card-menu"
                                            ref={activeCardRef}
                                            style={{
                                                ...styles.cardMenu,
                                                top: 'auto',
                                                right: 'auto',
                                                left: '50%',
                                                transform: 'translateX(-100%)',
                                            }}
                                        >
                                            <p onClick={() => { setRenameScriptId(currentScript.id); setActiveCard(null); }}>Rename</p>
                                            <p onClick={() => { handleEditScript(currentScript); setActiveCard(null); }}>Edit</p>
                                            <p onClick={() => { handleDeleteScript(category, currentScript); setActiveCard(null); }}>
                                                Delete
                                            </p>
                                            {hasMultipleVersions && (
                                                <p onClick={() => { handleVersionButtonClick(baseName); setActiveCard(null); }}>Version</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {editScript === currentScript.id && (
                                <>
                                    <textarea
                                        style={styles.editContent}
                                        value={temporaryEditContent}
                                        onChange={(e) => setTemporaryEditContent(e.target.value)}
                                    />
                                    <div style={styles.editActions}>
                                        <button
                                            style={styles.saveEditButton}
                                            onClick={() => handleSaveEdit(category, currentScript.id)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            style={styles.cancelEditButton}
                                            onClick={handleCancelEditScript}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            )}
                            {versionOverlay === baseName && (
                                <div style={styles.versionOverlay} ref={versionOverlayRef}>
                                    {scriptsList.map((version) => (
                                        <p key={version.id} style={styles.versionItem} onClick={() => handleSelectVersion(version)}>
                                            {version.name}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    )
                })}
            </>
        );
    };

    return (
        <div style={styles.overlay}>
            <div style={{ ...styles.overlayContent, width: '80%', margin: '0 auto' }}>
                <div style={styles.overlayHeader}>
                    <h3 style={styles.overlayHeaderText}>Scripts</h3>
                    <MdClose style={styles.closeIcon} onClick={closeOverlay} />
                </div>

                {selectedScript && (
                    <div style={{ backgroundColor: darkModeColors.foreground, padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
                        <p>Currently Selected: </p>
                        <p style={{ color: darkModeColors.primary }}>{selectedScript}</p>
                        <button style={{ ...styles.deselectButton, marginTop: '1px' }} onClick={handleDeselectScript}>
                            Deselect Script
                        </button>
                    </div>
                )}

                <div style={styles.category}>
                    <h4 style={styles.categoryTitle}>Web Apps</h4>
                    <div style={styles.addScript}>
                        <MdAdd style={styles.addScriptIcon} onClick={() => handleAddScriptClick("webApps")} />
                    </div>
                    {showAddForm.webApps && (
                        <div style={styles.addScriptForm}>
                            <input id="webApps-name-input" type="text" placeholder="Script Name" style={styles.input} />
                            <input id="webApps-content-input" type="text" placeholder="Script Content" style={styles.input} />
                            <button
                                style={styles.saveScriptButton}
                                onClick={() => handleSaveScript("webApps")}
                            >
                                Save Script
                            </button>
                            <button
                                style={styles.cancelScriptButton}
                                onClick={() => handleCancelAddScript("webApps")}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {renderScripts("webApps")}
                </div>

                <div style={styles.category}>
                    <h4 style={styles.categoryTitle}>OpenSCAD</h4>
                    <div style={styles.addScript}>
                        <MdAdd style={styles.addScriptIcon} onClick={() => handleAddScriptClick("openSCAD")} />
                    </div>
                    {showAddForm.openSCAD && (
                        <div style={styles.addScriptForm}>
                            <input id="openSCAD-name-input" type="text" placeholder="Script Name" style={styles.input} />
                            <input id="openSCAD-content-input" type="text" placeholder="Script Content" style={styles.input} />
                            <button
                                style={styles.saveScriptButton}
                                onClick={() => handleSaveScript("openSCAD")}
                            >
                                Save Script
                            </button>
                            <button
                                style={styles.cancelScriptButton}
                                onClick={() => handleCancelAddScript("openSCAD")}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {renderScripts("openSCAD")}
                </div>
            </div>
        </div>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    overlayContent: {
        backgroundColor: darkModeColors.foreground,
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        color: darkModeColors.text,
    },
    overlayHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    overlayHeaderText: {
        margin: 0,
    },
    closeIcon: {
        fontSize: '24px',
        cursor: 'pointer',
        color: darkModeColors.primary,
    },
    category: {
        marginBottom: '20px',
    },
    categoryTitle: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    addScript: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    addScriptIcon: {
        fontSize: '24px',
        cursor: 'pointer',
        color: darkModeColors.primary,
    },
    addScriptForm: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    saveScriptButton: {
        padding: '10px',
        backgroundColor: darkModeColors.primary,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    cancelScriptButton: {
        padding: '10px',
        backgroundColor: darkModeColors.border,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '5px',
    },
    input: {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #444',
        backgroundColor: darkModeColors.foreground,
        color: darkModeColors.text,
        outline: 'none',
    },
    scriptCard: {
        backgroundColor: darkModeColors.foreground,
        border: `1px solid ${darkModeColors.border}`,
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    scriptCardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    scriptName: {
        color: darkModeColors.text,
        fontSize: '15px',
        marginBottom: '16px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
    },
    selectButton: {
        padding: '5px 10px',
        backgroundColor: darkModeColors.primary,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    versionButton: {
        padding: '5px 10px',
        backgroundColor: darkModeColors.border,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    playIcon: {
        fontSize: '20px',
        cursor: 'pointer',
        color: darkModeColors.primary,
        marginRight: '10px',
    },
    moreIcon: {
        fontSize: '24px',
        cursor: 'pointer',
        color: darkModeColors.primary,
        position: 'relative',
    },
    cardMenu: {
        backgroundColor: darkModeColors.foreground,
        border: `1px solid ${darkModeColors.primary}`,
        borderRadius: '5px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'auto',
        right: 'auto',
        left: '50%',
        transform: 'translateX(-100%)',
        zIndex: 1001,
    },
    renameInput: {
        border: '1px solid #444',
        borderRadius: '5px',
        backgroundColor: darkModeColors.foreground,
        color: darkModeColors.text,
        padding: '5px',
        outline: 'none',
    },
    editContent: {
        width: '100%',
        border: '1px solid #444',
        borderRadius: '5px',
        backgroundColor: darkModeColors.foreground,
        color: darkModeColors.text,
        padding: '0px',
        outline: 'none',
        resize: 'none',
        marginBottom: '10px',
        marginTop: '10px',
    },
    editActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
    },
    saveEditButton: {
        padding: '10px',
        backgroundColor: darkModeColors.primary,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    cancelEditButton: {
        padding: '10px',
        backgroundColor: darkModeColors.border,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    deselectButton: {
        padding: '10px 20px',
        backgroundColor: darkModeColors.primary,
        color: darkModeColors.text,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '50%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        marginTop: '20px',
    },
    versionOverlay: {
        position: 'absolute',
        top: '100%',
        left: '0%',
        backgroundColor: darkModeColors.foreground,
        border: `1px solid ${darkModeColors.border}`,
        borderRadius: '5px',
        zIndex: 1002,
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        overflow: 'hidden',
    },
    versionItem: {
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: darkModeColors.foreground,
        borderBottom: `1px solid ${darkModeColors.border}`,
    },
};

export default ScriptsOverlay;