import React, { useState } from 'react';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { saveBalanceToFirestore } from '../control/firebase';
import { PAYPAL_CLIENT_ID } from '../config';

const Paypal = () => {
    let userID = localStorage.getItem("userID");
    const [balance, setBalance] = useState(Number(localStorage.getItem(`${userID}_balance`)) || 100);
    const [editMode, setEditMode] = useState(false);
    const [newBalance, setNewBalance] = useState(balance);
    const [infoOverlayVisible, setInfoOverlayVisible] = useState(false);

    const toggleInfoOverlay = () => {
        setInfoOverlayVisible(!infoOverlayVisible);
    };

    const tokensLeftInput = (balance / 2.5) * 1000000;
    const tokensLeftOutput = (balance / 10) * 1000000;
    const tokensPerImage = 765;
    const tokensInImages = Math.floor(tokensLeftOutput / tokensPerImage);

    let tokensLeftInputPretty = tokensLeftInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let tokensLeftOutputPretty = tokensLeftOutput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let tokensInImagesPretty = tokensInImages.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const initialOptions = {
        "client-id": PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
    };

    const handleEdit = () => setEditMode(true);
    const handleCancel = () => {
        setEditMode(false);
        setNewBalance(balance);
    };

    const handleSave = () => {
        setBalance(newBalance);
        let userID = localStorage.getItem("userID");
        localStorage.setItem(`${userID}_balance`, newBalance);
        saveBalanceToFirestore(userID, newBalance);
        setEditMode(false);
    };

    return (
        <div style={styles.container}>
            <button style={styles.backButton} onClick={() => window.history.back()}>Back</button>
            <h2 style={styles.header}>Tokens and Balance</h2>
            <div style={styles.info}>
                {editMode ? (
                    <div>
                        <input
                            type="number"
                            value={newBalance}
                            onChange={(e) => setNewBalance(Number(e.target.value))}
                            style={styles.input}
                        />
                        <button onClick={handleSave} style={styles.button}>Save</button>
                        <button onClick={handleCancel} style={styles.button}>Cancel</button>
                    </div>
                ) : (
                    <div>
                        <p>Current Balance: ${balance.toFixed(2)}</p>
                        <button onClick={handleEdit} style={styles.button}>Edit Balance</button>
                    </div>
                )}
                <p>Input Tokens: {tokensLeftInputPretty} tokens</p>
                <p>Output Tokens: {tokensLeftOutputPretty} tokens</p>
                <p>Images: {tokensInImagesPretty} Images</p>
            </div>
            <button style={styles.infoButton} onClick={toggleInfoOverlay}>ℹ️</button>
            {infoOverlayVisible && (
                <div style={styles.overlay}>
                    <p>Understanding LLM Tokens:</p>
                    <p>$2.50 / 1 million input tokens</p>
                    <p>$10.00 / 1 million output tokens</p>
                    <p>300 characters = 74 tokens</p>
                    <p>1 image (1000x1000) = 765 tokens</p>
                    <button onClick={toggleInfoOverlay} style={styles.button}>Close</button>
                </div>
            )}
            <PayPalScriptProvider options={initialOptions}>
                <Checkout />
            </PayPalScriptProvider>
        </div>
    );
};

const Checkout = () => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency || "USD");

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    };

    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: "10.00",
                },
            }],
        });
    };

    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then(details => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
        });
    };

    return (
        <div className="checkout" style={styles.checkout}>
            {isPending ? <p>Loading...</p> : (
                <>
                    <select value={currency} onChange={onCurrencyChange} style={styles.dropdown}>
                        <option value="USD">💵 USD</option>
                        <option value="EUR">💶 Euro</option>
                    </select>
                    <div style={styles.paypalButtonContainer}>
                        <PayPalButtons
                            style={styles.paypalButtons}
                            createOrder={onCreateOrder}
                            onApprove={onApproveOrder}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#36393f',
        color: 'white',
        minHeight: '100vh',
    },
    header: {
        color: 'white',
    },
    info: {
        margin: '20px 0',
    },
    input: {
        padding: '5px',
        fontSize: '16px',
    },
    button: {
        margin: '5px',
        padding: '5px 10px',
        backgroundColor: '#7289da',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    backButton: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        backgroundColor: '#7289da',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '5px 10px',
    },
    infoButton: {
        backgroundColor: '#7289da',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        marginBottom: '20px',
        cursor: 'pointer',
        padding: '5px',
    },
    overlay: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '20px',
        borderRadius: '10px',
        zIndex: '1000',
    },
    paypalButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    paypalButtons: {
        layout: 'vertical',
        shape: 'rect',
        color: 'gold',
        label: 'paypal',
        height: 35, // Adjust the height to make the button smaller
    },
    dropdown: {
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '10px',
    },
    checkout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
};

export default Paypal;