// OpenAI Chat
export const openaiChat = async (userPrompt: string, systemPrompt: string, model: string = 'gpt-4o-2024-08-06', base64Image: string = "") => {
    let responseMessage = "";
    try {
        let open_ai_api_key = localStorage.getItem('openai_api_key');
        const withImage = base64Image !== "";
        let content;
        if (withImage) {
            content = [
                {
                    "type": "text",
                    "text": userPrompt
                },
                {
                    "type": "image_url",
                    "image_url": {
                        "url": base64Image
                    }
                }
            ];
        } else {
            content = userPrompt;
        }
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${open_ai_api_key}`
            },
            
            body: JSON.stringify({
                "model": model,
                "messages": [
                    {
                        "role": "system",
                        "content": systemPrompt
                    },
                    {
                        "role": "user",
                        "content": content
                    }
                ]
            })
        });
        const data = await response.json();
        responseMessage = data.choices[0].message.content;
    } catch (error) {
        console.error(error);
        // alert uer that they need to check their OpenAI API Key or their openai account
        alert("Response Error: please check your internet connection or OpenAI API Key / permissions.");
        responseMessage = "Response Error: please check your internet connection or OpenAI API Key / permissions.";
    }
    return responseMessage;
}


// OpenAI Image Generation
export const openaiImageGeneration = async (prompt: string, model: string = 'dall-e-3') => {
    let open_ai_api_key = localStorage.getItem('openai_api_key');
    const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${open_ai_api_key}`
        },
        body: JSON.stringify({
            "model": model,
            "prompt": prompt,
            "n": 1,
            "size": "1024x1024"
        })
    });
    const data = await response.json();
    return data;
}


// OpenAI Embed
export const openaiEmbed = async (text: string) => {
    try {
        let open_ai_api_key = localStorage.getItem('openai_api_key');
        const response = await fetch('https://api.openai.com/v1/embeddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${open_ai_api_key}`
            },
            body: JSON.stringify({
                "input": text,
                "model": "text-embedding-3-small",
                "encoding_format": "float"
            })
        });
        const data = await response.json();
        return data.data[0].embedding;
    } catch (error) {
        console.error(error);
        // alert user that they need to check their OpenAI API Key or their openai account
        alert("Please check your OpenAI API Key or your OpenAI account.");
        return "";
    }
}