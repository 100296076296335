// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAoYFV3Br2ryvGVXHJDRzEiixosd2VunU8",
  authDomain: "ditto-app-dev.firebaseapp.com",
  projectId: "ditto-app-dev",
  storageBucket: "ditto-app-dev.appspot.com",
  messagingSenderId: "22790208601",
  appId: "1:22790208601:web:b6ee532b2e8a048d1a0548",
  measurementId: "G-KJFMK6PHML",
  googleSearchEngineAPIKey: "AIzaSyA1C9Znkw962qI6smGUo2RkVWcHGSob0yU",
  googleSearchEngineId: "f218df4dacc78457d",
  webSocketURL: "wss://websocket-server-m4cg7rn54q-uc.a.run.app"
};