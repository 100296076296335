import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StatusIcons from "./StatusIcons";
import ScriptsOverlay from "./ScriptsOverlay";
import MemoryOverlay from "./MemoryOverlay";
import { statusTemp } from "../control/status";

import { syncLocalScriptsWithFirestore } from "../control/firebase";

function StatusBar() {
    const navigate = useNavigate();
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isMemoryOverlayOpen, setIsMemoryOverlayOpen] = useState(false);
    const [selectedScript, setSelectedScript] = useState(
        localStorage.getItem("workingOnScript") ? JSON.parse(localStorage.getItem("workingOnScript")).script : null
    );

    let webApps = JSON.parse(localStorage.getItem("webApps")) || [];
    let openSCAD = JSON.parse(localStorage.getItem("openSCAD")) || [];
    // if either are not empty, sort them by name in ascending order
    if (webApps.length > 0) {
        webApps.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (openSCAD.length > 0) {
        openSCAD.sort((a, b) => a.name.localeCompare(b.name));
    }

    const [scripts, setScripts] = useState({
        webApps: webApps,
        openSCAD: openSCAD,
    });

    const handleSettingsClick = () => {
        navigate("/settings");
    };

    const handleBookmarkClick = async () => {
        setIsOverlayOpen(!isOverlayOpen);
        await syncLocalScripts();
    };

    const handleMemoryClick = () => {
        setIsMemoryOverlayOpen(!isMemoryOverlayOpen);
    };

    const syncLocalScripts = async () => {
        let userID = localStorage.getItem('userID')
        await syncLocalScriptsWithFirestore(userID, "webApps")
        await syncLocalScriptsWithFirestore(userID, "openScad")
        let webApps = JSON.parse(localStorage.getItem("webApps")) || [];
        let openSCAD = JSON.parse(localStorage.getItem("openSCAD")) || [];
        // if either are not empty, sort them by name in ascending order
        if (webApps.length > 0) {
            webApps.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (openSCAD.length > 0) {
            openSCAD.sort((a, b) => a.name.localeCompare(b.name));
        }
        setScripts({
            webApps: webApps,
            openSCAD: openSCAD,
        });
    }

    useEffect(() => {
        if (localStorage.getItem("workingOnScript")) {
            setSelectedScript(JSON.parse(localStorage.getItem("workingOnScript")).script);
            let webAppsJSON = JSON.parse(localStorage.getItem("webApps")) || [];
            let openSCADJSON = JSON.parse(localStorage.getItem("openSCAD")) || [];
            // if either are not empty, sort them by name in ascending order
            if (webAppsJSON.length > 0) {
                webAppsJSON.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (openSCADJSON.length > 0) {
                openSCADJSON.sort((a, b) => a.name.localeCompare(b.name));
            }
            setScripts({
                webApps: webAppsJSON,
                openSCAD: openSCADJSON,
            });
        } else {
            setSelectedScript(null);
        }
    }, [localStorage.getItem("workingOnScript")]);

    useEffect(() => {
        let webApps = scripts.webApps;
        let openSCAD = scripts.openSCAD;
        localStorage.setItem("webApps", JSON.stringify(webApps));
        localStorage.setItem("openSCAD", JSON.stringify(openSCAD));
    }, [scripts]);

    return (
        <div style={styles.statusBar}>
            <div style={styles.status}>
                <p style={styles.statusText}>Status:</p>
                <p style={styles.statusIndicator}>{statusTemp.status}</p>
            </div>

            <StatusIcons
                handleSettingsClick={handleSettingsClick}
                handleBookmarkClick={async () => { handleBookmarkClick() }}
                handleMemoryClick={handleMemoryClick}
                selectedScript={selectedScript}
            />

            <div style={styles.status}>
                <p style={styles.statusText}>Volume:</p>
                <p style={styles.statusIndicator}>{statusTemp.volume}</p>
            </div>

            {isOverlayOpen && (
                <ScriptsOverlay
                    scripts={scripts}
                    setScripts={setScripts}
                    selectedScript={selectedScript}
                    setSelectedScript={setSelectedScript}
                    closeOverlay={() => setIsOverlayOpen(false)}
                />
            )}

            {isMemoryOverlayOpen && (
                <MemoryOverlay
                    closeOverlay={() => setIsMemoryOverlayOpen(false)}
                />
            )}
        </div>
    );
}

const styles = {
    statusBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
    },
    status: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    statusText: {
        paddingRight: "5px",
        color: "#FFFFFF",
    },
    statusIndicator: {
        color: "green",
    },
};

export default StatusBar;